import React from "react"
import { StaticImage } from "gatsby-plugin-image"
// components
import GridElement from "../components/teamElement"
import TopElement from "../components/topElement"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CraftServicesPage = () => {
  return (
    <Layout
      initialBackgroundColor="bg-white"
      headerTitle="Leistung und Leidenschaft"
      headerSub="Über Uns"
      bgImg="ueberUns"
      topHeight="h-screen md:h-850"
    >
      <Seo title="Über Uns" />

      <div className="max-w-screen-lg mx-auto">
        <StaticImage
          src="../assets/images/company/team-gruppenbild.jpg"
          alt=""
          className="relative object-contain -mt-24 bg-black md:h-720 h-400 sm:-mt-48 md:-mt-450"
        />
        <TopElement
          title="Unsere Werte"
          text="Was zeichnet ein gutes Unternehmen aus? Vielleicht ganz einfach dies: dass jede Arbeit und jeder einzelne Arbeitsschritt als eine echte Unternehmung betrachtet wird. Dazu braucht es Leistungswille, Leidenschaft und ein klares Ziel.
Unser Ziel ist klar: Ihre Zufriedenheit. Dafür setzen wir unsere ganze Kraft und unser ganzes Know-how ein. Unsere Leidenschaft ist ebenfalls klar: bestes Handwerk. Diese Leidenschaft hat bei uns seit Jahrzehnten Tradition.
Gegründet wurde unsere Firma 1964 von Viktor Hossmann. 1993 übernahm sein Sohn Adriano die Geschäftsleitung – nachdem er das Unternehmen bereits zehn Jahre zusammen mit seinem Vater geführt hatte.
Verlassen kann sich Adriano Hoss- mann auf dasselbe wie die Kundinnen und Kunden der Firma Hossmann: auf starke Mitarbeiterinnen und Mitarbeiter."
        />

        <div className="team-grid">
          <GridElement
            title="Adriano Hossmann"
            subTitle="Inhaber & Geschäftsführer"
            text="Adriano Hossmann absolvierte von 1972 bis 1975 die Gipserlehre. Nach einigen Lehr- und Wanderjahren erlangte er im Jahre 1983 die Meisterprüfung und stieg in die Geschäftsführung des elterlichen Betriebs ein. 10 Jahre führten Vater und Sohn die Firma gemeinsam. 1993 übergab Viktor Hossmann den Betrieb an seine Nachfolger."
          >
            <StaticImage
              src="../assets/images/company/team-adriano-hossmann.jpg"
              alt=""
              className="bild"
            />
          </GridElement>
          <GridElement
            title="Gianluca Hossmann"
            subTitle="Immobilien Graubünden und Zürich"
            text="Gianluca Hossmann absolvierte von 2006 bis 2010 das Sportgymnasium an der Minerva Zürich. Nach fünfjähriger Karriere im Profisport wechselte er im Jahre 2015 in die Berufswelt und arbeitete unter anderem in den Bereichen Finanz- und Immobilienbuchhaltung, Immobilienverwaltung und -bewirtschaftung, sowie der Immobilienentwicklung. Parallel zu seiner beruflichen Tätigkeit, erlangte er seinen Abschluss in Betriebsökonomie an der Hochschule für Wirtschaft in Zürich.
Seit 2018 ist er in weiten Teilen für den strategischen Part, sowie Akquisition und Planung neuer Projekte der Hossmann Immobilien AG verantwortlich."
          >
            <StaticImage
              src="../assets/images/company/team-gianluca-hossmann.jpg"
              alt=""
              className="bild"
            />
          </GridElement>
          <GridElement
            title="Mauro Hossmann"
            subTitle="Gips und Trockenbau Zürich"
            text="Mauro Hossmann absolvierte von 2009 bis 2013 die Hotelfachschule in Passugg und schloss als Kaufmann mit eidg. Berufsmatura ab. Er entschied sich danach, eine Gipserlehre zu absolvieren und in den Familienbetrieb einzusteigen. Seit 2018 ist er für die Firma in Zürich verantwortlich und bildete sich berufsbegleitend zum Dipl. Techniker HF in Bauplanung/Architektur weiter."
          >
            <StaticImage
              src="../assets/images/company/team-mauro-hossmann.jpg"
              alt=""
              className="bild"
            />
          </GridElement>
          <GridElement
            title="Marco von Ow"
            subTitle="Bauleiter Graubünden"
            text="Marco von Ow absolvierte von 2008 bis 2011 eine Maurerlehre EFZ bei Luzi Bau AG in Zillis. Danach besuchte er die Vorarbeiterschule Hoch- Tiefbau und bildete sich von 2018-2019 zum eidg. Baupolier weiter. Seit April 2020 ist er bei uns als Bauleiter tätig. Er ist im Betrieb als Projektleiter für die Organisation der Baustellen verantwortlich."
          >
            <StaticImage
              src="../assets/images/company/team-marco-von-ow.jpg"
              alt=""
              className="bild"
            />
          </GridElement>
          <GridElement
            title="Bryan Attenhofer"
            subTitle="Kaufmann Graubünden und Zürich"
            text="Bryan Attenhofer absolvierte von 2008 bis 2014 die KV Lehre bei Prevost AG in Thusis. Seit 2014 ist er in unserem Betrieb tätig. Von 2016 bis 2019 hat er sich berufsbegleitend zum Dipl. Betriebswirtschafter HF weitergebildet. Er ist im Betrieb für die Administration und Auftragsbearbeitung verantwortlich und erste Ansprechperson."
          >
            <StaticImage
              src="../assets/images/company/team-bryan-attenhofer.jpg"
              alt=""
              className="bild"
            />
          </GridElement>
        </div>
      </div>
    </Layout>
  )
}

export default CraftServicesPage
